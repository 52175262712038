import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';

import StationNameRenderer from './StationNameRenderer.js';

import RiverGauge from './RiverGauge.js'; // TODO, React.Lazy

import { formatTimestamp } from '../lib/formatHelpers.js';

import statusInfo from '../lib/statusInfo.js';
const statusText = statusInfo.statusText;

function AssociatedDefences({ associatedDefences, mapFloodDefences }) {
	if(!associatedDefences || !mapFloodDefences) {
		return null;
	} else {
		let defences = associatedDefences.reduce(function(acca, defence) {
			let resolvedDefence = null;
			if(defence.assetSubType === "Water Storage Area") {
				resolvedDefence = mapFloodDefences.waterStorage[defence.assetId];
			}
			acca.push(
				<Row key={defence.assetId}>
					<Col xs={12}><b>Water Storage Area</b>: {resolvedDefence.defenceName}</Col>
				</Row>
			);

			return acca;
		}, [])

		if(defences.length < 1) {
			return null;
		}

		return [<Row key="df_title">
			<Col xs={12} className="bg-secondary"><b>This area forms part of a flood defence</b></Col>
		</Row>].concat(defences);
	}
}

function FloodAreasModal({ activeFAModel, mfaKey, mfa, onHide, classes, styles, floodAlert, mapFloodDefences, baseStations, displayStation }) {

	let station = null;
	if(baseStations && mfa && mfa.metadata && mfa.metadata.stationId) {
		station = baseStations[mfa.metadata.stationId];
	}
	if(!station || !displayStation) {
		console.error("Can't resolve one of/both of station or displayStation in flood modal", station, displayStation);
		console.error("lookup was: " + mfa.metadata.stationId);
		return null;
	}

	let stationDiffers = displayStation.stationId !== station.stationId || displayStation.qualifier !== station.qualifier;

	/*let polygons = mfa.polygons;
	// just render the first, thats what we are assuming for now anyway
	if(polygons.length > 0) {
		let polygon = mfa.polygons[0];
		let vertexPositions = polygon.reduce(function(acca, vertex) {
			acca.push([vertex.lat, vertex.lng])
			return acca;
		}, []);
		// <Polygon
		// 	key={polygons[0]}
		// 	pathOptions={{ color: "blue" }}
		// 	positions={vertexPositions}
		// />
	}*/

	let warningBox = null;
	if(mfa.status === "IN_FLOOD" || mfa.status === "IN_PARTIAL_FLOOD") {
		warningBox = <Col md={12} style={{fontSize: "0.6em"}} className="text-center"><br /><b>Please note: It is unsafe to walk or drive through floodwaters. The depth and flow rate can quickly change, making conditions unpredictable and hazardous. We strongly recommend you avoid flooded areas.</b></Col>;
	}

	return <Modal show={activeFAModel === mfaKey} contentClassName="bg-dark text-white" onHide={onHide}>
		        <Modal.Header variant="dark" closeButton>
		          <Modal.Title className="text-white"><h2 className="hX-blendin">{mfa.name}</h2></Modal.Title>
		        </Modal.Header>
		        <Modal.Body>
		        	<Row className="text-white">
		        		<Col md={12} className={classes.join(", ")} style={styles}><h3><center>{statusText[mfa.status] || "Unknown"}</center></h3></Col>
		        		{warningBox}
		        	</Row>
		        	<hr />
		        	{mfa.description && <div><Row className="text-white">
		        		<Col md={6}><b>Section of {mfa.name}</b></Col>
		        		<Col md={6}>{mfa.description}</Col>
		        	</Row><hr /></div>}
		        	{station.stationName && <div><Row className="text-white">
		        		<Col xs={12} sm={5} md={5}>Measurement Station:</Col>
		        		<Col xs={12} sm={7} md={7}><strong><StationNameRenderer stationName={station.stationName} qualifierType={station.qualifier} /></strong></Col>
		        		<Col xs={12}><b></b></Col>
		        		<Col xs={12} className="d-none d-lg-block">{station && <RiverGauge title="Current" readingTime={formatTimestamp(station.entryTimestamp)} entryHeight={station.entryHeight}/>}</Col>
						<Col xs={12} className="d-none d-lg-block">{station && station.forecastMax && <RiverGauge title="Forecast" readingTime={formatTimestamp(station.forecastMaxTimestamp)} entryHeight={station.forecastMax}/>}</Col>
						<Col xs={12} className="d-lg-none">{station && <RiverGauge.Tiny title="Current" readingTime={formatTimestamp(station.entryTimestamp)} entryHeight={station.entryHeight}/>}</Col>
						<Col xs={12} className="d-lg-none">{station && station.forecastMax && <RiverGauge.Tiny title="Forecast" readingTime={formatTimestamp(station.forecastMaxTimestamp)} entryHeight={station.forecastMax}/>}</Col>
						{stationDiffers && <Col xs={12} style={{fontSize: "0.7em"}}>
							<i>This flood area uses a secondary measurement station.</i><br />
							<i>The primary station for this FloodNav is <b><StationNameRenderer stationName={displayStation.stationName} qualifierType={displayStation.qualifier} /></b></i>
						</Col>}
		        	</Row><hr /></div>}
		        	{mfa.attributes && mfa.attributes.isCauseway && mfa.metadata.floodingCurrentHeight > 0 && <div><Row className="text-white">
		        		<Col xs={12} className="text-center"><strong>- This is a causeway -</strong></Col>
		        		<Col xs={12} className="text-center">The estimated water height, at the lowest point, is: <strong>{parseFloat(mfa.metadata.floodingCurrentHeight).toFixed(2)}m</strong></Col>
		        		<Col xs={12} className="text-center" style={{fontSize: "0.7em"}}>
		        			<i>Height as calculated based upon the previous available data point @ <b>{formatTimestamp(station.entryTimestamp)}</b>. Calculation subject to a target margin of error of +/- 0.05m, but this may be more in some circumstances.</i>
		        		</Col>
		        	</Row><hr /></div>}
		        	{mfa.metadata.beganFloodingTimestamp && <Row className="text-white">
		        		<Col xs={7} style={{textAlign: "right"}}><strong>Began Flooding at :</strong></Col>
		        		<Col xs={5}>{formatTimestamp(mfa.metadata.beganFloodingTimestamp)}</Col>
		        	</Row>}
		        	<Row className="text-white">
		        		<Col xs={7} style={{textAlign: "right"}}><strong>Starts Flooding at :</strong></Col>
		        		<Col xs={5}>{Math.floor(Math.round(100*mfa.metadata.floodingPartialThreshold))/100}m</Col>
		        		{(!mfa.attributes || !mfa.attributes.isCauseway) && <Col xs={7} style={{textAlign: "right"}}><strong>Fully Flooded at :</strong></Col>}
		        		{(!mfa.attributes || !mfa.attributes.isCauseway) && <Col xs={5}>{Math.floor(Math.round(100*mfa.metadata.floodingFullThreshold))/100}m</Col>}
		        	</Row>
		        	{mfa.metadata.lastFloodedTime && <Row>
		        		<Col xs={7} style={{textAlign: "right"}}><strong>Last Flooding Ended :</strong></Col>
		        		<Col xs={5}>{formatTimestamp(mfa.metadata.lastFloodedTime)}</Col>
	        		</Row>}
		        	{floodAlert.length > 0 && <><hr/ ><Row>
		        		<Col xs={12}><b>This area has active alerts:</b></Col>
		        		</Row></>}
		        	{floodAlert}
		        	<AssociatedDefences associatedDefences={mfa.associatedDefences} mapFloodDefences={mapFloodDefences} />
		        </Modal.Body>
	      </Modal>;
}

export default FloodAreasModal;