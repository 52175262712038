
// Dependencies
import React, { Suspense, useEffect } from 'react';
import { Routes, Route, useParams, Outlet, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './i18n/i18n';  // Initialize i18n

// import { useDispatch } from 'react-redux';

// Bootstrap Elements
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// CSS
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'flag-icons/css/flag-icons.min.css';

import GlobalNav from './components/GlobalNav.js';

import UncaughtBoundary from './errors/UncaughtBoundary.js';

import loadable from '@loadable/component';

import { CONTEXTS } from './store/reducers/appContext.js';
import { useTranslation } from 'react-i18next';

import CommonHeaders from './components/helmet-headers/CommonHeaders.js';

import { useFM } from './FMContext';

// Views
const HomePage = loadable(() => {
  return import('./views/HomePage.js')
});
const HomePageApp = loadable(() => {
  return import('./views/HomePageApp.js')
}, { ssr: false });
const DataExplorer = loadable(() => import('./views/DataExplorer.js'));
const DefenceExplorer = loadable(() => import('./views/DefenceExplorer.js'));
const PlaceDefenceExplorer = loadable(() => import('./views/PlaceDefenceExplorer.js'));
const FloodNav = loadable(() => import('./views/FloodNav.js'));
const FloodNavHowTo = loadable(() => import('./views/FloodNavHowTo.js'));
const BasinsView = loadable(() => import('./views/BasinsView.js'));
const RiverBasinsView = loadable(() => import('./views/RiverBasinsView.js'));
const ManagementCatchmentsView = loadable(() => import('./views/ManagementCatchmentsView.js'));
const OperationalCatchmentsView = loadable(() => import('./views/OperationalCatchmentsView.js'));
const WaterBodyView = loadable(() => import('./views/WaterBodyView.js'));
const FloodOverviewView = loadable(() => import('./views/FloodOverviewView.js'));
const PrivacyPolicy = loadable(() => import('./views/PrivacyPolicy.js'));
const CopyrightCredits = loadable(() => import('./views/CopyrightCredits.js'));
const DataAccuracy = loadable(() => import('./views/DataAccuracy.js'));
// const StationView = loadable(() => import('./views/StationView.js'));
const SewageSearch = loadable(() => import('./views/SewageSearch.js'));
const SewageSearchPlace = loadable(() => import('./views/SewageSearchPlace.js'));
const SewageSearchOperator = loadable(() => import('./views/SewageSearchOperator.js'));
const SewageOperatorMap = loadable(() => import('./views/SewageOperatorMap.js'));
const StationView = loadable(() => import('./views/StationView.js'));
const StationHistoricalView = loadable(() => import('./views/StationHistoricalView.js'));
const StationFlowModelView = loadable(() => import('./views/StationFlowModelView.js'));
const StationHistoricalFloodView = loadable(() => import('./views/StationHistoricalFloodView.js'));
const FloodAlertsSummary = loadable(() => import('./views/FloodAlertsSummary.js'));
const FloodAlertView = loadable(() => import('./views/FloodAlertView.js'));
const View404 = loadable(() => import('./views/View404.js'));
const MetOfficeFGS = loadable(() => import('./views/MetOfficeFGS.js'));
const CCStationMap = loadable(() => import('./views/CCStationMap.js'));
const CCStationSelector = loadable(() => import('./views/CCStationSelector.js'));
const ManageAppAccount = loadable(() => import('./views/ManageAppAccount.js'), { ssr: false, fallback: null});
// Components
const ApplicationContextInitialiser = loadable(() => import('./components/ApplicationContextInitialiser.js'), { ssr: false, fallback: null });
const AppNetworkStatus = loadable(() => import('./components/AppNetworkStatus.js'), { ssr: false, fallback: null });


function LanguageViaOutlet() {
  const { lang } = useParams();  // Capture the :lang parameter from the URL
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const allowedLanguages = Object.keys(i18n.options.resources);

  // Switch the language based on the :lang parameter
  useEffect(() => {
    if (lang) {
      if (allowedLanguages.includes(lang)) {
        // If lang is valid and different from the current i18n language, change it
        if (i18n.language !== lang) {
          i18n.changeLanguage(lang);
        }
      } else {
        // Redirect to 404 page or default language if lang is unsupported
        navigate("/not-found", { replace: true });
      }
    } else if (i18n.language !== 'en') {
      // Default to English if no :lang parameter
      i18n.changeLanguage('en');
    }
  }, [lang, i18n, allowedLanguages, navigate]);

  return <Outlet />;
}

function routeList({ subdomain, appContext, pathMountPoint }) {
  return <Route path={pathMountPoint} element={<LanguageViaOutlet />}>
  {/* These are for the homepage website */}
                  {subdomain === "www" && appContext !== CONTEXTS.APP_CONTEXT_XPA ? <Route path={pathMountPoint+"/"} element={<HomePage />} /> : null}
                  {subdomain === "www" && appContext === CONTEXTS.APP_CONTEXT_XPA ? <Route path={pathMountPoint+"/"} element={<HomePageApp />} /> : null}

                  {appContext === CONTEXTS.APP_CONTEXT_XPA ? <Route path="/acc-manage" element={<ManageAppAccount />} /> : null}

                  {/* These are across all endpoints */}
                  <Route path={pathMountPoint+"/copyright-information"} element={<CopyrightCredits />} />
                  <Route path={pathMountPoint+"/data-accuracy"} element={<DataAccuracy />} />
                  <Route path={pathMountPoint+"/privacy-policy"} element={<PrivacyPolicy />} />

                  <Route path={pathMountPoint+"/data-explorer"} element={<DataExplorer />} />

                  <Route path={pathMountPoint+"/data-explorer/stationmap"} element={<CCStationSelector />} />
                  <Route path={pathMountPoint+"/data-explorer/stationmap/:countryCode"} element={<CCStationMap />} />

                  <Route path={pathMountPoint+"/data-explorer/fgs"} element={<MetOfficeFGS day="today" />} />
                  <Route path={pathMountPoint+"/data-explorer/fgs/previous"} element={<MetOfficeFGS day="previous" />} />

                  <Route path={pathMountPoint+"/data-explorer/defences"} element={<DefenceExplorer />} />
                  <Route path={pathMountPoint+"/data-explorer/defences/place/:placeId/:placeName"} element={<PlaceDefenceExplorer />} />


                  <Route path={pathMountPoint+"/data-explorer/basins"} element={<RiverBasinsView />} />
                  <Route path={pathMountPoint+"/data-explorer/basins/:basinName"} element={<BasinsView />} />
                  <Route path={pathMountPoint+"/data-explorer/management-catchments/:managementCatchmentName"} element={<ManagementCatchmentsView mode="management-catchments" />} />
                  <Route path={pathMountPoint+"/data-explorer/river-sub-units/:riverSubUnitName"} element={<ManagementCatchmentsView mode="river-sub-units" />} />
                  <Route path={pathMountPoint+"/data-explorer/operational-catchments/:operationalCatchmentName"} element={<OperationalCatchmentsView />} />
                  <Route path={pathMountPoint+"/data-explorer/water-bodies/:waterBodyName"} element={<WaterBodyView />} />

                  <Route path={pathMountPoint+"/data-explorer/search-sewage-report"} element={<SewageSearch />} />
                  <Route path={pathMountPoint+"/data-explorer/search-sewage-report/:placeId/:placeName"} element={<SewageSearchPlace historical={false} />} />
                  <Route path={pathMountPoint+"/data-explorer/search-sewage-report/:placeId/:placeName/historical"} element={<SewageSearchPlace historical={true} />} />

                  <Route path={pathMountPoint+"/data-explorer/search-sewage-operator"} element={<SewageSearchOperator />} />
                  <Route path={pathMountPoint+"/data-explorer/search-sewage-operator/:operatorName"} element={<SewageOperatorMap />} />

                  <Route path={pathMountPoint+"/floodnav"} element={<FloodNav />} />
                  <Route path={pathMountPoint+"/floodnav/how-to"} element={<FloodNavHowTo />} />

                  <Route path={pathMountPoint+"/stations/:stationId"} element={<StationView />} />
                  <Route path={pathMountPoint+"/stations/:stationId/metric/:metricId"} element={<StationView />} />
                  <Route path={pathMountPoint+"/stations/:stationId/historical"} element={<StationHistoricalView />} />
                  <Route path={pathMountPoint+"/stations/:stationId/historical/flood/:dateTime"} element={<StationHistoricalFloodView />} />
                  <Route path={pathMountPoint+"/stations/:stationId/downstream"} element={<StationView qualifierType="Downstream Stage" />} />
                  <Route path={pathMountPoint+"/stations/:stationId/flow"} element={<StationView qualifierType="Flow" />} />
                  <Route path={pathMountPoint+"/stations/:stationId/flow/model"} element={<StationFlowModelView />} />
                  <Route path={pathMountPoint+"/stations/:stationId/rainfall"} element={<StationView qualifierType="Rainfall" />} />
                  <Route path={pathMountPoint+"/stations/:stationId/water-temperature"} element={<StationView qualifierType="WaterTemperature" />} />
                  
                  <Route path={pathMountPoint+"/flood-alerts"} element={<FloodAlertsSummary />} />
                  <Route path={pathMountPoint+"/flood-alerts/:floodAlertAreaId"} element={<FloodAlertView />} />

                  {/* These are for the individual flood map side of things, we need both / and /* ! */}
                  {subdomain !== "www" && <Route path={pathMountPoint+"/"} element={<FloodOverviewView renderSubdomain={subdomain} />} />}
                  {subdomain !== "www" && <Route path={pathMountPoint+"/*"} element={<FloodOverviewView renderSubdomain={subdomain} />} />}
                  {subdomain !== "www" && <Route path={pathMountPoint+"/map/:id"} element={<FloodOverviewView renderSubdomain={subdomain} />} />}
                  {subdomain === "www" && <Route path={pathMountPoint+"/floodnav/:floodNavName/*"} element={<FloodOverviewView renderSubdomain={subdomain} />} />}
                  {subdomain === "www" && <Route path={pathMountPoint+"/floodnav/:floodNavName/map/:id"} element={<FloodOverviewView renderSubdomain={subdomain} />} />}


                  {/* 404 Handler. look above, as !www is just sent to the FloodNav */}
                  <Route path={pathMountPoint+"/*"} element={<View404 />} />
                  <Route path={pathMountPoint+"/not-found"} element={<View404 />} />
  </Route>;
}


function App({ }) {
  const { appContext } = useSelector(state => state.appContext);
  const { t } = useTranslation();
  const { currentSubdomain, currentHostname, currentProtocol, currentPort } = useFM();

  let subdomain = 'www';
  if(currentSubdomain) {
    subdomain = currentSubdomain;
  }

  console.log("Render Target: ", subdomain);

  //className="g-0"
  return (
    <div className="App">
          <CommonHeaders
            title={t('SITEWIDE.TITLE_DEFAULT')}
            description={t('SITEWIDE.DESCRIPTION_DEFAULT')}
          />
          <ApplicationContextInitialiser />
          <GlobalNav />
            <Container fluid  style={{paddingTop: "56px"}}>
              {appContext === CONTEXTS.APP_CONTEXT_XPA ? <AppNetworkStatus /> : null}
              <UncaughtBoundary>
                <Routes>
                  <Route path="/:lang">
                    {routeList({subdomain: subdomain, appContext: appContext, pathMountPoint: "/:lang"})}
                  </Route>
                  {routeList({subdomain: subdomain, appContext: appContext, pathMountPoint: ""})}
                </Routes>
              </UncaughtBoundary>

              {appContext !== CONTEXTS.APP_CONTEXT_XPA ? <Row style={{fontSize: "0.6em", paddingTop: "37px", paddingBottom: "37px"}}>
                <Col xs={12}>
                  <i><b>FloodMapper</b> is an independent site and is not associated with any government bodies (e.g. Environment Agency).</i>
                </Col>
                <Col xs={12}>Copyright &copy; <b>FloodMapper</b> 2023-2024.</Col>
                <Col xs={12}>All Rights Reserved</Col>
              </Row> : null}
            </Container>
    </div>
  );
}

export default App;
