


export const StationLinkCalculator = function (stationId, qualiferType) {
	let suffix = "";
	if(qualiferType === "Downstream Stage") {
		suffix = '/downstream';
	}
	return "/stations/" + stationId + suffix;
};


export const OperationalCatchmentsLinkCalculator = function (operationalCatchtmentName) {
	return `/data-explorer/operational-catchments/${operationalCatchtmentName}`;
}


export const SubUnitLinkCalculator = function(subUnitName) {
	return `/data-explorer/river-sub-units/${subUnitName}`;
}


export const StationHistoricalFlowLinkCalculator = function(stationId, stationUrlSlug) {
	let stationUrlPart = stationId;
	if(stationUrlSlug) {
		stationUrlPart = stationUrlSlug; // prefer slug if present
	}

	return `/stations/${stationUrlPart}/flow/model`;
}