

export const formatTimestamp = function(timestamp) {
    const date = new Date(timestamp);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
        return <b><i>[Invalid Date]</i></b>;
    }

    // Get the current year
    const currentYear = new Date().getFullYear();

    // Extract the time in HH:mm format (if valid)
    const time = !isNaN(date.getHours()) && !isNaN(date.getMinutes())
        ? date.toLocaleTimeString(undefined, {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false, // Uses 24-hour format
        })
        : null;

    // Extract the day and month (if valid)
    const dayMonth = !isNaN(date.getDate()) && !isNaN(date.getMonth())
        ? date.toLocaleDateString(undefined, {
            day: '2-digit',
            month: 'short',
        })
        : null;

    // Extract the year if it differs from the current year
    const year =
        date.getFullYear() && date.getFullYear() !== currentYear
            ? ` ${date.getFullYear()}`
            : '';

    // Build the result string if parts are valid
    let result = [time, dayMonth].filter(Boolean).join(' '); // Join only valid parts
    if (result) {
        result += year; // Append the year if valid and needed
    } else {
        result = <b><i>[Invalid Date]</i></b>; // All parts are invalid
    }

    return result;
};